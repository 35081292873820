import { useNavigate } from "react-router-dom";
import moment from "moment";
import BlogService from "../../services/blogService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import { useState, useEffect } from "react";

const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const limitTitleChar = (value, index) => {
  if (value.blog_title?.length > 40) {
    return value.blog_title?.substring(0, 40) + " ...";
  } else {
    return value.blog_title;
  }
};
const columns = [
  {
    label: "Published On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Blog title", key: "blog_title", valueFunc: limitTitleChar },
  { label: "Author", key: "author" },
  {
    label: "Views",
    key: "viewCount",
    altValue: "000",
  },
  {
    label: "Bookmarks",
    key: "bookmarkCount",
    altValue: "000",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "blog_title--asc", value: "A to Z" },
  { key: "blog_title--desc", value: "Z to A" },
];

export default function Blogs() {
  const navigate = useNavigate();
  const blogServ = new BlogService();
  const [totalCount, setTotalCount] = useState(0);
  const [monthCount, setmonthCount] = useState(0);
  useEffect(() => {
    getBlogCount();
  }, []);

  const getBlogCount = async () => {
    try {
      let response = await blogServ.listCount({});
      setTotalCount(response.totalCount);
      setmonthCount(response.monthTotal);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    {
      title: "Blog Articles Count",
      icon: "/images/glossary/Blog.svg",
      value: totalCount,
      footer: `+${monthCount} this month`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await blogServ.listAll(activity);
      response.data = response.data.map((rec) => {
        rec.category_id = rec.category_id?._id ? rec.category_id.name : "";
        return rec;
      });
      response.data = response.data.map((rec) => {
        rec.author = rec.author?._id ? rec.author.user_name : "";
        return rec;
      });
      if (response) {
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const editHandler = async (id) => {
    navigate("/blogs_form/" + id);
  };

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await blogServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const viewHandler = async (id) => {
    window.open(process.env.REACT_APP_FRONTEND_BASEURL + "/blogs/" + id, "_blank");
  };
  const analyticsHandler = (id) => {
    console.log(id);
    navigate("/blogs/blogs_analytics/" + id);
  };
  // const deleteHandler = async (id) => {
  //   try {
  //     if (window.confirm("Are you sure you want to delete this record?")) {
  //       let response = await blogServ.deleteRecord(id);
  //       if (response) {
  //         return response;
  //       } else {
  //         return false;
  //       }
  //     }
  //   } catch (err) {
  //     return false;
  //   }
  // };

  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await blogServ.updateRecord(obj);
      if (response?.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search blogs"
        buttonLink="/blogs_form"
        buttonText="Post a Blog"
        buttonLink2="/blog_draft"
        buttonText2="View Drafts"
        buttonIcon2="/images/svg-icons/viewDraftIcon.svg"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        editHandlerFunc={editHandler}
        viewHandlerFunc={viewHandler}
        analyticsHandlerFunc={analyticsHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
      />
    </div>
  );
}
