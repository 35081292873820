import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDeletePopup from "../../shared/ConfirmDeletePopup/ConfirmDeletePopup";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement,
  elements,
} from "chart.js";
import BlogService from "../../services/blogService";

function BlogAnalytics() {
  const blogServ = new BlogService();
  const [blogDetails, setBlogDetails] = useState();
  const [analyticsDetails, setAnalyticsDetails] = useState();
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [notes, setNotes] = useState("");
  const [yearlyViews, setYearlyViews] = useState();
  const [yearlyusers, setYearlyUsers] = useState();
  const [yearlyShare, setYearlyShare] = useState();
  const [usersTypes, setUsersTypes] = useState();
  const [emptyGraphMsgView, setEmptyGraphMsgView] = useState("");
  const [emptyGraphMsgUser, setEmptyGraphMsgUser] = useState("");
  const [emptyGraphMsgShare, setEmptyGraphMsgShare] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    console.log(params.id);
    getBlogDetails(params.id);
    getBlogAnalytics(params.id);
    getYearlyViews(params.id);
    getYearlyUsers(params.id);
    getYearlyShares(params.id);
    getUsersTypes(params.id);
  }, []);
  const getUsersTypes = async () => {
    try {
      let response = await blogServ.getUsersTypes({ blogId: params?.id });
      setUsersTypes(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyViews = async () => {
    try {
      let response = await blogServ.getYearlyViews({ blogId: params?.id });
      setYearlyViews(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgView(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyUsers = async () => {
    try {
      let response = await blogServ.getYearlyUsers({ blogId: params?.id });
      setYearlyUsers(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgUser(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyShares = async () => {
    try {
      let response = await blogServ.getYearlyShares({ blogId: params?.id });
      setYearlyShare(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgShare(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getBlogAnalytics = async () => {
    try {
      let response = await blogServ.getAnalytics({ blogId: params?.id });
      setAnalyticsDetails(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getBlogDetails = async () => {
    try {
      let response = await blogServ.getDetails(params?.id);
      if (response.data) {
        console.log(response);
        setBlogDetails(response);
      } else {
      }
    } catch (err) {
      throw err;
    }
  };
  const activeToggleHandlerFunc = async (blog) => {
    // console.log(blog._id)
    try {
      let isActive = !blog.is_active;
      let obj = {
        is_active: isActive,
        _id: blog._id,
      };
      let res = await blogServ.updateRecord(obj);
      console.log(res);
      if (res?.data?.message?.includes("Updated Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (obj, index) => {
    console.log(obj);
    let changed = await activeToggleHandlerFunc(obj);
    if (changed) {
      toast.success("Updated Successfully");
      getBlogDetails(params.id);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const deleteHandler = async () => {
    try {
      if (true) {
        let response = await blogServ.deleteRecord(params.id);
        if (response) {
          navigate("/blogs");
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const onNotesSubmit = async () => {
    let payload = {
      _id: params.id,
      notes: notes,
    };
    try {
      let response = await blogServ.updateRecord(payload);
    } catch (error) {
      console.log(error);
    }
  };
  const yearlyViewsdata = {
    labels: yearlyViews?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyViews?.map((v, i) => {
          return v.count;
        }),
        borderRadius: 6,
        borderSkipped: false,
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
      },
    ],
  };
  const yearlyUsersdata = {
    labels: yearlyusers?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyusers?.map((v, i) => {
          return v.count;
        }),
        borderRadius: 6,
        borderSkipped: false,
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
      },
    ],
  };
  const yearlyShareData = {
    labels: yearlyShare?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyShare?.map((v, i) => {
          return v.count;
        }),
        borderRadius: 6,
        borderSkipped: false,
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
      },
    ],
  };
  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 4,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const pieData = {
    datasets: [
      {
        data: [usersTypes?.existingUser, usersTypes?.newUser, usersTypes?.otherUser],
        backgroundColor: ["#2B4EBE", "#FFBE0B", "#4FD939"],
        borderWidth: 0,
      },
    ],
  };
  const pieOption = { cutout: 38 };
  return (
    <div className="CourseAnalyticsDiv">
      <div className="analyticsHeader bBottom">
        <div className="d-flex">
          <div className="analyticsbackBtn" onClick={() => navigate("/blogs")}>
            <img src="/images/icons/back-icon.svg" />
          </div>
          <div className="courseAnalyticsTitle ">
            <div className="d-flex">
              <div>
                <img src={blogDetails?.data?.blog_image} />
              </div>
              <div>
                <p className="analyticsTitle m-0">{blogDetails?.data?.blog_title}</p>
                <p className="authorName m-0">Author: {blogDetails?.data?.full_name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex borderT">
          <div className="publishedOnDiv">
            <p className="publishedonP1">Published On</p>
            <p className="m-0 publishedonP2">{moment(blogDetails?.data?.createdAt).format("DD/MM/YYYY")}</p>
          </div>
          <div className={"statusDiv " + (!blogDetails?.data?.is_active ? " padding17_0" : " ")}>
            <p className="publishedonP1">Status</p>
            <div className="tbl_status d-flex align-items-center justify-content-center">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={blogDetails?.data?.is_active}
                  onChange={() => activeToggleHandler(blogDetails?.data)}
                />
                <span className="slider round " style={{ width: "30px", marginRight: "10px" }} />
              </label>
              {blogDetails?.data?.is_active ? <label>Active</label> : <label>Inactive</label>}
            </div>
          </div>
          <div className="actionDiv">
            <p className="publishedonP1">Action</p>
            <p className="publishedonP2 mb-0">
              <img
                onClick={() => navigate("/blogs/blogs_form/" + params.id)}
                src="/images/svg-icons/editActionIcon.svg"
              />
              <a href={process.env.REACT_APP_FRONTEND_BASEURL + "/blogs/" + params.id} target="blank">
                <img src="/images/svg-icons/viewActionIcon.svg" className="actionViewMargin" />
              </a>
              <img onClick={() => setShowDeletePop(true)} src="/images/svg-icons/deleteActionIcon.svg" />
            </p>
          </div>
        </div>
      </div>
      <div className="courseAnalyticsMid">
        <h2>Blog Analytics</h2>
        <div className="analyticsGridBox">
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Views:</p>
              <h6>{analyticsDetails?.uniqueViews}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Users:</p>
              <h6>{analyticsDetails?.uniqueUsers}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Shares:</p>
              <h6>{analyticsDetails?.shares}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Total Current Bookmarks:</p>
              <h6>{analyticsDetails?.currBookmarks}</h6>
            </div>
          </div>
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Title:</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Title:</p>
              <h6>000</h6>
            </div>
          </div>
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Title:</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Title:</p>
              <h6>000</h6>
            </div>
          </div>
        </div>
        <div className="analyticsGraphGrid">
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Views</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgView == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyViewsdata} height={100} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Users</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgUser == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyUsersdata} height={100} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Shares</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgShare == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyShareData} height={100} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Types of Users</p>
              <h6>All-time</h6>
            </div>
            <div className="row">
              <div className="col-6 pieChartLabel">
                <div className="d-flex">
                  <div className="circle1"></div>{" "}
                  <div>
                    Existing: <span>{Math.round(usersTypes?.existingUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle2"></div>{" "}
                  <div>
                    New: <span>{Math.round(usersTypes?.newUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle3"></div>{" "}
                  <div>
                    Other: <span>{Math.round(usersTypes?.otherUser)}%</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Doughnut data={pieData} options={pieOption} width={100} height={0} />
              </div>
            </div>
          </div>
        </div>
        <div className="notesDiv" style={{ height: "265px" }}>
          <p>Notes</p>
          <textarea
            placeholder="Start typing..."
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
              onNotesSubmit(e.target.value);
            }}
          />
        </div>
      </div>
      <ToastContainer />
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteHandler}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
    </div>
  );
  return (
    <div className="CourseAnalyticsDiv">
      <div className="CourseAnalyticsHead">
        <div className="analyticsbackBtn" onClick={() => navigate("/blogs")}>
          <img src="/images/icons/back-icon.svg" />
        </div>
        <div className="courseAnalyticsTitle ">
          <div className="d-flex">
            <div>
              <img src={blogDetails?.data?.blog_image} />
            </div>
            <div>
              <p className="analyticsTitle m-0">{blogDetails?.data?.blog_title}</p>
              <p className="authorName m-0">Author: {blogDetails?.data?.author?.full_name}</p>
            </div>
          </div>
        </div>
        <div className="publishedOnDiv">
          <p className="publishedonP1">Published On</p>
          <p className="m-0 publishedonP2">{moment(blogDetails?.data?.createdAt).format("DD/MM/YYYY")}</p>
        </div>
        <div className={"statusDiv " + (!blogDetails?.data?.is_active ? " padding17_0" : " ")}>
          <p className="publishedonP1">Status</p>
          <div className="tbl_status d-flex align-items-center justify-content-center">
            <label className="switch">
              <input
                type="checkbox"
                checked={blogDetails?.data?.is_active}
                onChange={() => activeToggleHandler(blogDetails?.data)}
              />
              <span className="slider round " style={{ width: "30px", marginRight: "10px" }} />
            </label>
            {blogDetails?.data?.is_active ? <label>Active</label> : <label>Inactive</label>}
          </div>
        </div>
        <div className="actionDiv">
          <p className="publishedonP1">Action</p>
          <p className="publishedonP2 mb-0">
            <img onClick={() => navigate("/blogs/blogs_form/" + params.id)} src="/images/svg-icons/Edit.svg" />
            <img src="/images/svg-icons/Eye.svg" className="margin0_12px" />
            <img onClick={() => setShowDeletePop(true)} src="/images/svg-icons/Delete.svg" />
          </p>
        </div>
      </div>
      <div className="courseAnalyticsMid">
        <h2>Blog Analytics</h2>
        <div className="analyticsGridBox">
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Views:</p>
              <h6>{analyticsDetails?.uniqueViews}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Users:</p>
              <h6>{analyticsDetails?.uniqueUsers}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Shares:</p>
              <h6>{analyticsDetails?.shares}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Total Current Bookmarks:</p>
              <h6>{analyticsDetails?.currBookmarks}</h6>
            </div>
          </div>
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Title:</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Title:</p>
              <h6>000</h6>
            </div>
          </div>
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Title:</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Title:</p>
              <h6>000</h6>
            </div>
          </div>
        </div>
        <div className="analyticsGraphGrid">
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Views</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgView == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyViewsdata} width={100} height={50} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Users</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgUser == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyUsersdata} width={100} height={50} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Shares</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgUser == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyShareData} width={100} height={50} options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Types of Users</p>
              <h6>All-time</h6>
            </div>
            <div className="row">
              <div className="col-6 pieChartLabel">
                <div className="d-flex">
                  <div className="circle1"></div>{" "}
                  <div>
                    Existing: <span>{Math.round(usersTypes?.existingUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle2"></div>{" "}
                  <div>
                    New: <span>{Math.round(usersTypes?.newUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle3"></div>{" "}
                  <div>
                    Other: <span>{Math.round(usersTypes?.otherUser)}%</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Doughnut data={pieData} options={pieOption} width={100} height={0} />
              </div>
            </div>
          </div>
        </div>
        <div className="notesDiv" style={{ height: "265px" }}>
          <p>Notes</p>
          <div>
            <textarea
              placeholder="Start typing..."
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
                onNotesSubmit(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteHandler}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
    </div>
  );
}

export default BlogAnalytics;
