import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import blogService from "../../services/blogService";
import TagService from "../../services/TagService";
import UserService from "../../services/UserService";
import TagsInput from "../Courses/TagsInput";
import Steps from "./StepsDraft";
import Dropify from "../../shared/Dropify/Dropify";

export default function BlogFormDraft() {
  const params = useParams();
  const router = useNavigate();
  const blogServ = new blogService();
  const userServ = new UserService();
  const tagServ = new TagService();
  const [showLoading, setShowLoading] = useState(true);
  const [catList, setCatList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [defaultTagList, setDefaultTagList] = useState([]);
  const [blogImgPrev, setBlogImgPrev] = useState("");
  const [value, setValue] = useState({
    blog_title: "",
    author: "",
    category_id: "",
    read_duration: "",
    content: "",
    blog_image: "",
    tags: [],
    hero_image: "",
  });

  useEffect(() => {
    getUserList();
    getCatList();
    getTagList();
    if (params?.id) {
      getBlogDetail(params?.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getTagList() {
    try {
      const response = await tagServ.listAll();
      if (response.data) {
        let prTag = [];
        response.data.map((item) => {
          let obj = { value: item.title, label: item.title };
          prTag = [...prTag, obj];
        });
        setTagList(prTag);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getUserList() {
    try {
      let activity = {
        filter: { role: "author" },
      };
      const response = await userServ.userList(activity);
      if (response.data) {
        setUserList(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getCatList() {
    try {
      let activity = {};
      const response = await blogServ.BlogCatList(activity);
      if (response.data) {
        setCatList(response.data);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  const getBlogDetail = async (id) => {
    try {
      let response = await blogServ.getDetailsDraft(id);
      if (response) {
        setValue({
          _id: response.data._id,
          blog_title: response.data.blog_title,
          author: response.data.author._id,
          category_id: response.data.category_id,
          read_duration: response.data.read_duration,
          content: response.data.content,
          blog_image: response.data.blog_image,
          hero_image: response.data.hero_image,
          tags: response.data.tags,
        });
        setBlogImgPrev(response.data.blog_image);
        let defaultTag = [];
        response.data.tags.forEach((item) => {
          let obj = { value: item, label: item };
          defaultTag = [...defaultTag, obj];
        });
        setDefaultTagList([...defaultTag]);
      } else {
        toast.error(response?.error);
      }
    } catch (err) {
      throw err;
    }
  };
  const callBackTags = (tags) => {
    formik.setFieldValue("tags", tags);
    const addNewTag = async (tags) => {
      let response = await tagServ.addTag(tags);
      if (response) {
        console.log(response);
      }
    };
    addNewTag(tags);
  };
  const ValidateSchema = Yup.object({
    blog_title: Yup.string().required("Title is a required field"),
    author: Yup.string().required("Author is a required field"),
    category_id: Yup.string().required("Category is a required field"),
    read_duration: Yup.string().required("Duration is a required field"),
    content: Yup.string().required("Content is a required field"),
    blog_image: Yup.string().required("Blog image is required field"),
    hero_image: Yup.string(),
    // hero_image: Yup.mixed().test({
    //   message: "Please set image resolution to (" + 1660 + "*" + 640 + ")",
    //   test: function (value) {
    //     return new Promise((resolve, reject) => {
    //       const image = new Image();
    //       let fr = new FileReader();
    //       fr.onload = function () {
    //         if (fr !== null && typeof fr.result === "string") {
    //           image.src = fr.result;
    //         }
    //       };
    //       image.onload = async function () {
    //         const width = image.width;
    //         const height = image.height;
    //         if (width === parseInt(1660) && height === parseInt(640)) {
    //           resolve(true);
    //         } else {
    //           resolve(false);
    //         }
    //       };
    //       fr.readAsDataURL(value);
    //     });
    //   },
    // }),
  });

  const onSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    let obj = { ...values };
    try {
      let response;
      if (obj._id !== undefined && obj._id !== "") {
        const formData = new FormData();
        formData.append("_id", obj._id);
        formData.append("author", obj.author);
        formData.append("blog_title", obj.blog_title);
        formData.append("category_id", obj.category_id);
        formData.append("content", obj.content);
        formData.append("read_duration", obj.read_duration);
        formData.append("blog_image", obj.blog_image);
        formData.append("hero_image", obj.hero_image);
        formData.append("tags", JSON.stringify(obj.tags));
        response = await blogServ.updateRecordDraft(formData);
        if (response.data) {
          toast.success("Blog updated successfully");
          window.scroll(0, 0);
          setTimeout(() => {
            router(`/blogs/blogs_form_draft_step2/${params?.id}`);
          }, 1500);
        }
      } else {
        const formData = new FormData();
        formData.append("author", obj.author);
        formData.append("blog_title", obj.blog_title);
        formData.append("category_id", obj.category_id);
        formData.append("content", obj.content);
        formData.append("read_duration", obj.read_duration);
        formData.append("blog_image", obj.blog_image);
        formData.append("hero_image", obj.hero_image);
        formData.append("tags", JSON.stringify(obj.tags));
        response = await blogServ.addrecordDraft(formData);
        if (response.data) {
          window.scroll(0, 0);
          toast.success("Blog added successfully");
          setTimeout(() => {
            router(`/blogs/blogs_form_draft_step2/${response.data?.data?._id}`);
          }, 1000);
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/blog_draft">
                <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="rightBox order-2 order-sm-3">
            <h5>Step 1</h5>
            <h6>Information</h6>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img src="/images/icons/blog.svg" alt="backicon" className="img-fluid" /> Post a Blog Article
              </h4>
            </div>
            <div className="paginationNav">
              <Steps step={1} blogId={params?.id} />
            </div>
          </div>
        </div>
        <div className="userProfile_details comPadding">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3 pb-3 pb-xl-0">
                <div className="mb-4">
                  <label htmlFor="Tags" className="form-label">
                    Cover Image
                  </label>
                  <div className="imageUploder">
                    {/* <Dropify
                      id="input-file-uploader_blog_image"
                      name="blog_image"
                      value={formik.values.blog_image}
                      text="Please upload the cover image <br> <span>(1048 x 564px)</span>"
                      changeCallBk={(event) => {
                        if (event.currentTarget.files[0]) {
                          formik.setFieldValue(
                            "blog_image",
                            event.currentTarget.files[0]
                          );
                        } else {
                          formik.setFieldValue("blog_image", "");
                        }
                      }}
                    /> */}
                    <div className="blogImgPrev">
                      {blogImgPrev == "" ? (
                        <>
                          <img src="/images/svg-icons/uploadDropImg.svg" className="blogUploadIcon" />
                          <p>Please upload the cover image</p>
                          <p>(1048 x 564px)</p>
                        </>
                      ) : (
                        <img src={blogImgPrev} className="blogPrev" />
                      )}

                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          formik.setFieldValue("blog_image", e.currentTarget.files[0]);
                          setBlogImgPrev(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="row postpageform">
                  <div className="col-md-6">
                    <div className="mb-4 account_input">
                      <label htmlFor="Title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Title"
                        placeholder="Please enter the title"
                        name="blog_title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.blog_title}
                      />
                      {formik.touched.blog_title && formik.errors.blog_title ? (
                        <div className="formik-errors bg-error">{formik.errors.blog_title}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4 account_input">
                      <label htmlFor="for" className="form-label">
                        Blog Tags
                      </label>
                      <TagsInput
                        callBackTags={callBackTags}
                        tags={formik.values.tags}
                        formik={formik}
                        isSearchable
                        prevTags={tagList}
                        defualtTag={defaultTagList}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4 account_input">
                      <label htmlFor="Author" className="form-label">
                        Author
                      </label>
                      <div>
                        <div className="custom-select fa-caret-down">
                          <select
                            className={"form-control " + (formik.values.author ? "" : "color-fade")}
                            name="author"
                            onChange={formik.handleChange}
                            value={formik.values.author}
                          >
                            <option value="">Select Author</option>
                            {userList &&
                              userList.map((user, idx) => {
                                return (
                                  <option value={user._id} key={idx}>
                                    {user.user_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {formik.touched.author && formik.errors.author ? (
                          <div className="formik-errors bg-error">{formik.errors.author}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4 account_input">
                      <label htmlFor="ReadTime" className="form-label">
                        Category
                      </label>
                      <div>
                        <div className="custom-select fa-caret-down" style={{ zIndex: "0" }}>
                          <select
                            className={"form-control " + (formik.values.category_id ? "" : "color-fade")}
                            name="category_id"
                            onChange={formik.handleChange}
                            value={formik.values.category_id}
                          >
                            <option value="">Select Category</option>
                            {catList &&
                              catList.map((cat, idx) => {
                                return (
                                  <option value={cat._id} key={idx}>
                                    {cat.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {formik.touched.category_id && formik.errors.category_id ? (
                          <div className="formik-errors bg-error">{formik.errors.category_id}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4" style={{ zIndex: "0" }}>
                    <div className="mb-4 account_input">
                      <label htmlFor="ReadTime" className="form-label">
                        Read Time
                      </label>
                      <div className="custom-select fa-caret-down">
                        <input
                          type="text"
                          className="form-control m-0"
                          placeholder="Select Read Time"
                          name="read_duration"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.read_duration}
                        />
                        {formik.touched.read_duration && formik.errors.read_duration ? (
                          <div className="formik-errors bg-error">{formik.errors.read_duration}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-12" style={{ zIndex: "0" }}>
                    <div className="mb-4 account_input modelFormInput">
                      <label htmlFor="Description" className="form-label">
                        Content
                      </label>
                      {/* <textarea className="form-control" placeholder="Please enter the content" rows={7}  */}
                      <Editor
                        apiKey={window.tinyAPIKEY}
                        init={{
                          plugins:
                            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                          toolbar:
                            "undo redo | bold italic underline strikethrough | blocks fontfamily fontsize |fullscreen| link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                        }}
                        value={formik.values.content}
                        onEditorChange={(e) =>
                          formik.handleChange({
                            target: { name: "content", value: e },
                          })
                        }
                      />
                      {formik.touched.content && formik.errors.content ? (
                        <div className="formik-errors bg-error">{formik.errors.content}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="commonform_btn d-grid col-12">
                <button
                  type="submit"
                  className={"btn btn-primary btn-block " + (!(formik.isValid && formik.dirty) ? " disabled" : "")}
                >
                  {!showLoading ? (
                    <>
                      <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                    </>
                  ) : (
                    <>Save &amp; Continue</>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="ljSectionData w-100 clearfix" id="ljSectionData">
  //     <div className="custom_link">
  //       <div className="users_bottom_part">
  //         <div className="total_updates_top ActiveLinks">
  //           <div className="custom-link_backbtn">
  //             <Link to="/blogs">
  //               <img
  //                 src="/assets/images/icons/leftarrow.svg"
  //                 alt="arrow"
  //                 className="ml-2"
  //                 style={{ paddingLeft: "8px" }}
  //               />
  //             </Link>
  //           </div>
  //           <div className="walletAddressHead accountChangeHead ">
  //             <h5 className="m-0">Create a Blog</h5>
  //           </div>
  //           <div className="d-flex active_link_customs">
  //             <p className="m-0 pt-2">
  //               Lorem Ipsum is simply dummy text of the printing and typesetting
  //               industry.
  //             </p>
  //           </div>
  //         </div>
  //         <div className="custom_link_form">
  //           <div className="custom_form CreateCustomLink">
  //             <div className="update_form accountInner p-0 border-0">
  //               <form onSubmit={formik.handleSubmit}>
  //                 <div
  //                   className="row"
  //                   style={{
  //                     paddingLeft: "20px",
  //                     paddingRight: "20px",
  //                     marginBottom: "20px",
  //                   }}
  //                 >
  //                   <div className="col-8 ">
  //                     <div className="form-group mb-3">
  //                       <label htmlFor="for">Title</label>
  //                       <input
  //                         type="text"
  //                         className="form-control m-0"
  //                         placeholder="Enter a title for this blog post"
  //                         name="blog_title"
  //                         onChange={formik.handleChange}
  //                         onBlur={formik.handleBlur}
  //                         value={formik.values.blog_title}
  //                       />
  //                       {formik.touched.blog_title &&
  //                       formik.errors.blog_title ? (
  //                         <div className="formik-errors bg-error">
  //                           {formik.errors.blog_title}
  //                         </div>
  //                       ) : null}
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4">
  //                     <div className="form-group m-0">
  //                       <label htmlFor="for">Course Tags</label>
  //                       <TagsInput
  //                         callBackTags={callBackTags}
  //                         tags={formik.values.tags}
  //                         formik={formik}
  //                         isSearchable
  //                         prevTags={tagList}
  //                         defualtTag={defaultTagList}
  //                       />
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 col-12">
  //                     <div className="form-group mb-3">
  //                       <label htmlFor="for">Author</label>
  //                       <div class="input-group">
  //                         <select
  //                           className="form-control"
  //                           name="author"
  //                           onChange={formik.handleChange}
  //                           value={formik.values.author}
  //                         >
  //                           <option>Select Author</option>
  //                           {userList &&
  //                             userList.map((user, idx) => {
  //                               return (
  //                                 <option value={user._id} key={idx}>
  //                                   {user.user_name}
  //                                 </option>
  //                               );
  //                             })}
  //                         </select>
  //                       </div>
  //                       {formik.touched.author && formik.errors.author ? (
  //                         <div className="formik-errors bg-error">
  //                           {formik.errors.author}
  //                         </div>
  //                       ) : null}
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 col-12">
  //                     <div className="form-group mb-3">
  //                       <label htmlFor="for">Category</label>
  //                       <div class="input-group">
  //                         <select
  //                           className="form-control"
  //                           name="category_id"
  //                           onChange={formik.handleChange}
  //                           value={formik.values.category_id}
  //                         >
  //                           <option>Select Category</option>
  //                           {catList &&
  //                             catList.map((cat, idx) => {
  //                               return (
  //                                 <option value={cat._id} key={idx}>
  //                                   {cat.name}
  //                                 </option>
  //                               );
  //                             })}
  //                         </select>
  //                       </div>
  //                       {formik.touched.category_id &&
  //                       formik.errors.category_id ? (
  //                         <div className="formik-errors bg-error">
  //                           {formik.errors.category_id}
  //                         </div>
  //                       ) : null}
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 col-12">
  //                     <div className="form-group mb-3">
  //                       <label htmlFor="for">Read Time</label>
  //                       <input
  //                         type="text"
  //                         className="form-control m-0"
  //                         placeholder="Select Read Time"
  //                         name="read_duration"
  //                         onChange={formik.handleChange}
  //                         onBlur={formik.handleBlur}
  //                         value={formik.values.read_duration}
  //                       />
  //                       {formik.touched.read_duration &&
  //                       formik.errors.read_duration ? (
  //                         <div className="formik-errors bg-error">
  //                           {formik.errors.read_duration}
  //                         </div>
  //                       ) : null}
  //                     </div>
  //                   </div>
  //                   <div className="col-12 ">
  //                     <div className="form-group mb-3">
  //                       <label htmlFor="for">Content</label>
  //                       <Editor
  //                         apiKey={window.tinyAPIKEY}
  //                         init={{
  //                           plugins: [
  //                             "advlist autolink lists link image charmap print preview anchor",
  //                             "searchreplace visualblocks code fullscreen",
  //                             "insertdatetime media table paste code help wordcount",
  //                           ],
  //                           toolbar:
  //                             "undo redo | formatselect | bold italic backcolor | \
  //                         alignleft aligncenter alignright alignjustify | \
  //                         bullist numlist outdent indent | removeformat | help",
  //                           menubar: false,
  //                         }}
  //                         value={formik.values.content}
  //                         onEditorChange={(e) =>
  //                           formik.handleChange({
  //                             target: { name: "content", value: e },
  //                           })
  //                         }
  //                       />

  //                       {formik.touched.content && formik.errors.content ? (
  //                         <div className="formik-errors bg-error">
  //                           {formik.errors.content}
  //                         </div>
  //                       ) : null}
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 p-3">
  //                     <label htmlFor="for">Blog Image</label>{" "}
  //                     <span className="star">*</span>
  //                     <div className="box">
  //                       {params.id ? (
  //                         <img
  //                           src={selectedImage}
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       ) : selectedImage ? (
  //                         <img
  //                           src={URL.createObjectURL(selectedImage)}
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       ) : (
  //                         <img
  //                           src="/assets/images/upload-image.png"
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       )}
  //                     </div>
  //                     <div className="form-group m-0">
  //                       <div className="form-group">
  //                         <div className="input-group">
  //                           <input
  //                             type="file"
  //                             className="form-control"
  //                             placeholder="Upload Profile Picture (Size - 830x280px)"
  //                             id="cover_image"
  //                             name="blog_image"
  //                             onChange={(event) => {
  //                               formik.setFieldValue(
  //                                 "blog_image",
  //                                 event.currentTarget.files[0]
  //                               );
  //                               imageChange(event);
  //                             }}
  //                           />
  //                           <div className="input-group-append">
  //                             <Link to="#">
  //                               <span className="input-group-text">
  //                                 <img
  //                                   src="/assets/images/icons/upload-to-cloud.svg"
  //                                   alt="upload-to-cloud"
  //                                   className="img-fluid"
  //                                 />
  //                               </span>
  //                             </Link>
  //                           </div>
  //                         </div>
  //                         {formik.touched.blog_image &&
  //                         formik.errors.blog_image ? (
  //                           <div className="formik-errors bg-error">
  //                             {formik.errors.blog_image}
  //                           </div>
  //                         ) : null}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 p-3">
  //                     <label htmlFor="for">Hero Image</label>{" "}
  //                     <span className="star">*</span>
  //                     <div className="box">
  //                       {params.id ? (
  //                         <img
  //                           src={selectedHeroImage}
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       ) : selectedHeroImage ? (
  //                         <img
  //                           src={URL.createObjectURL(selectedHeroImage)}
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       ) : (
  //                         <img
  //                           src="/assets/images/upload-image.png"
  //                           alt="default"
  //                           className="img-fulid"
  //                         />
  //                       )}
  //                     </div>
  //                     <div className="form-group m-0">
  //                       <div className="form-group">
  //                         <div className="input-group">
  //                           <input
  //                             type="file"
  //                             className="form-control"
  //                             placeholder="Upload Profile Picture (Size - 830x280px)"
  //                             id="hero_image"
  //                             name="hero_image"
  //                             onChange={(event) => {
  //                               formik.setFieldValue(
  //                                 "hero_image",
  //                                 event.currentTarget.files[0]
  //                               );
  //                               heroImageChange(event);
  //                             }}
  //                           />
  //                           <div className="input-group-append">
  //                             <Link to="#">
  //                               <span className="input-group-text">
  //                                 <img
  //                                   src="/assets/images/icons/upload-to-cloud.svg"
  //                                   alt="upload-to-cloud"
  //                                   className="img-fluid"
  //                                 />
  //                               </span>
  //                             </Link>
  //                           </div>
  //                         </div>
  //                         {formik.touched.hero_image &&
  //                         formik.errors.hero_image ? (
  //                           <div className="formik-errors bg-error">
  //                             {formik.errors.hero_image}
  //                           </div>
  //                         ) : null}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="col-12 p-4 custom-submitbtn">
  //                   <button type="submit" className="btn btnForm ">
  //                     Publish
  //                   </button>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <ToastContainer />
  //   </div>
  // );
}
